<template>
  <div class="admin-login min-h-screen container py-16 lg:p-32 flex items-center justify-center">
    <div class="login-container shadow-login rounded max-w-full flex flex-grow-0 flex-col items-center justify-center bg-white py-10 px-5 sm:p-10">
      <logo class="mx-auto mb-1" />

      <h1 class="mb-8">acesso ao painel de gestão</h1>

      <nivea-form :submit="submit" :error="error" error-message="Usuário ou senha inválidos" class="flex flex-col items-center justify-center">
        <field name="email" label="E-mail" rules="required|email" v-slot="{ label }">
          <v-text-field type="email" v-model="form.email" :label="label" autocomplete="username" hide-details="auto" />
        </field>

        <field name="password" label="Senha" rules="required" v-slot="{ label }">
          <v-text-field type="password" v-model="form.password" :label="label" autocomplete="current-password" hide-details="auto" />
        </field>

        <Button isLink class="self-start" :to="{ name: 'AdminForgotPassword' }">Esqueci minha senha</Button>

        <Button class="w-36 mt-10" type="submit" :loading="loading">Entrar</Button>
      </nivea-form>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import NiveaForm from '@/components/NiveaForm';
import Field from "@/components/fields/Field";
import Button from "@/components/Button";
import { mapActions } from 'vuex';

export default {
  name: "AdminLogin",
  components: {
    Logo,
    NiveaForm,
    Field,
    Button,
  },
  data() {
    return {
      loading: false,
      form: {
        email: '',
        password: ''
      }
    };
  },
  methods: {
    ...mapActions('admin', ['login']),
    async submit() {
      this.loading = true;
      await this.login(this.form);

      this.$router.push({
        name: 'Dashboard'
      });
    },
    error() {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.admin-login {
  .nivea-form {
    width: 360px;
  }
}
</style>
